<template>
  <div class="page-content">
    <carousel :data="carouseldata"></carousel>
    <div class="container">
      <div class="text-page-content">
        <h1 class="small-title mb-4">{{ $t('page_text.about_title') }}</h1>
        <div v-html="$t('page_text.about_text')"></div>
        <div class="bottom-buy-link">
          <p><strong>{{ $t('page_text.about_lozung') }}</strong></p>
          <router-link to="cart" :class="'button'">{{ $t('button.buy') }}</router-link>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import VueCarousel from '@chenfengyuan/vue-carousel';

export default {
  name: 'HelloWorld',
  components: {
    carousel: VueCarousel
  },
  data() {
    return {
      carouseldata: [
        '<div class="gar-slider gar-slide-1"></div>',
        '<div class="gar-slider gar-slide-2"></div>',
        '<div class="gar-slider gar-slide-3"></div>',
      ],
    };
  },
}
</script>

<style lang="scss">
.gar-slider {
  align-items: center;
  background-color: #666;
  display: flex;
  height: 50vw;
  background-size: cover;
  background-position: center;
  @media screen and (min-width: 768px) {
    height: 26vw;
    background-image: url(/assets/images/slide.jpg);
  }
  @media screen and (min-width: 1400px) {
    height: 500px;
  }
}
.gar-slide-1 {
  background-image: url(/assets/images/slider-1.jpg);
}
.gar-slide-2 {
  background-image: url(/assets/images/slider-2.jpg);
}
.gar-slide-3 {
  background-image: url(/assets/images/slider-3.jpg);
}
</style>
